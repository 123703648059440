import React, {useEffect, useState} from 'react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import 'react-calendar/dist/Calendar.css';
import {baseUrl, dateFormat, isLogin, placeholderImage, toBase64, truncateText} from "../utils";

import Select from 'react-select';
import {getBrandsApi, getPublicationApi} from "../store/actions/campaign";
import {useSelector} from "react-redux";
import {selectCurrentLanguage} from "../store/reducers/languageReducer";
import Skeleton from "react-loading-skeleton";
import {Breathing} from "react-shimmer";
import RateCard from "./RateCard";
import $ from "jquery";
import {selectUser} from "../store/reducers/userReducer";

let brandsData = [];

getBrandsApi(
    (response) => {
        brandsData = response.data.map((b) => ({value: b.id, label: b.name}));
    },
    (error) => {
        // setBrands([]);
    },
    (res) => {},
    'newspaper'
);
const NewsPaper = () => {
    const today = new Date(); // Current date and time
    today.setDate(today.getDate() - 11);
    let [date, onChange] = useState([today, new Date()]);
    const [Data, setData] = useState([]);
    const [brands, setBrands] = useState({label:'Mwananchi',value:'1'});

    const userData = useSelector(selectUser);
    const [isLoading, setIsLoading] = useState(true);
    const currentLanguage = useSelector(selectCurrentLanguage);
    const [showRateCard, setShowRateCard] = useState(false);

    const [rateCard, setRateCard] = useState({});

    const [subscription, setSubscription] = useState([]);

    const onDateChange = (value)=>{
        onChange(value);
        getNewsPaper(date,brands);
    }

    const handleBrandChange = (value)=>{
        setBrands(value);
        getNewsPaper(date,brands);
    }

    // breaking news api call
    useEffect(() => {
        getNewsPaper(date,brands);
    }, [currentLanguage,date, brands]);

    let getNewsPaper = function (date, brands){
        getPublicationApi(
            (response) => {
                const responseData = response.data;
                setData(responseData);
                // console.log(responseData);
                setIsLoading(false);
            },
            (error) => {
                setIsLoading(false);
                if (error === "No Data Found") {
                    setData('');
                }

            },
            (res)=>{},
            brands.value,
            dateFormat([date[0]])[0],
            dateFormat([date[1]])[0]
        );
    }

    function showHandle(item) {
        let i = item.pages != null ? item.pages : 0,
            uid = userData.data != null ? userData.data.id : 0,
            url = '/read/' + toBase64(item.cover + "~" + uid) + '/' + i;

        if(item.free === '1'){
            return window.location.href = url;
        }

        if (!isLogin())
            return $("#btnSignIn").click();


        if (subscription.length > 0) {
            subscription.map((d)=>{

                var exp = Date.parse(d["expired_at"]),
                    now = new Date(),
                    f = d["frequency"].toLowerCase(),
                    brands = [];

                if (f !== "daily") {
                    if (d['brand'].toString().length === 1) {
                        brands = [d["brand"]];
                    } else {
                        brands = d["brand"].split(',');
                    }

                    let contains = brands.includes(item.bid),
                        sub = contains && exp >= now;

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                } else {
                    let sub = item.bid.toString().toLowerCase() ===
                        d["brand"].toString().toLowerCase() &&
                        item.id.toString() === d["pid"].toString();

                    if (sub)
                        return window.location.href = url;
                    else
                        return handleRateCard(item);
                }
            })
        }else
            return handleRateCard(item);
    }

    function handleRateCard(item){
        setShowRateCard(true);
        setRateCard({"id": item.id, "bid": item.bid, "paper": item.brand,"title": item.title, "cover": item.cover,"pages": item.pages});
        return false;
    }

    return (

        <>
            <div className="container">
                <br/>
                <div className="row">
                    <div className="col-sm-6 col-lg-7"></div>
                    <div className="col-sm-3 col-lg-2 mt-2 rounded-3">
                        <Select
                            value={brands}
                            onChange={handleBrandChange}
                            options={brandsData}
                        />

                    </div>
                    <div className="col-sm-3">
                        <DateRangePicker onChange={onChange} value={date} /></div>
                </div>
            </div>

            <div className="container">
                {(Data && Data?.length > 0) ? (
                        <div>
                            {isLoading ? (
                                    // Show skeleton loading when data is being fetched
                                    <div className="col-12 col-sm-12 loading_data">
                                        <Skeleton height={20} count={22} />
                                    </div>
                                ) :
                                <div className="row mt-3">
                                    {(
                                        Data.map((product) => (
                                            <div className="col-sm-12 col-lg-2 pointer" key={product.id} onClick={() => showHandle(product)}>
                                                <div className="col-sm-12">
                                                    <img src={baseUrl() + "/public/docs/" + product.cover}
                                                         id="fs-Newscard-image"
                                                         fallback={<Breathing/>}
                                                         alt={product.title} onError={placeholderImage}/>
                                                </div>
                                                <div className="col-sm-12 col-12 ">
                                                    <div className="my-title">{truncateText(product.title, 30)}</div>
                                                    <p className="cat">
                                                        {truncateText(product.brand,)}
                                                        <span className="float-end">
                                                        {dateFormat([product.publish_at])}
                                                        </span>
                                                    </p>

                                                </div>
                                            </div>
                                        ))
                                    )}
                                </div>

                            }

                            {showRateCard && (
                                <RateCard show={showRateCard} item={rateCard}
                                          onHide={() => setShowRateCard(false)}></RateCard>
                            )}
                        </div>
                    )
                    : null}
            </div>
        </>
    )
}

export default NewsPaper;
