import React, {Fragment} from 'react';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrentLanguage } from "../store/reducers/languageReducer";
import {getfeaturesectionApi, getTodaynewspaperApi} from "../store/actions/campaign";
import StyleOne from "./StyleOne";
import StyleTwo from "./StyleTwo";
import StyleThree from "./StyleThree";
import StyleFour from "./StyleFour";
import StyleFive from "./StyleFive";
import { translate } from "../utils";
import Skeleton from "react-loading-skeleton";
import StyleTodayNewspaper from "./StyleTodayNewspaper";

const FeatureLayout = () => {
  // state
  const [Data, setData] = useState([]);

  // loader state
  const [isLoading, setIsLoading] = useState(true);

  // current language
  const currentLanguage = useSelector(selectCurrentLanguage);

  // breaking news api call
  useEffect(() => {
      getTodaynewspaperApi(
      (response) => {
            const responseData = response.data;
            setData([{"title":"Today", "news_type":"newspaper", "data":responseData}]);
            // setData(responseData);
        setIsLoading(false);
      },
      (error) => {
        setIsLoading(false);
        if (error === "No Data Found") {
          setData('');
        }
      }
    );
  }, [currentLanguage]);


    const SelectType = () => {
        return Data && Data.map((item) => {
            if(item.news_type === 'newspaper'){
                return <StyleTodayNewspaper key={'newspaper'}  isLoading={isLoading} Data={item} />;
            }
           return "";
        })


  };

    const   selectedComponent = SelectType();

    return (
        <Fragment key={selectedComponent.length}>
        {isLoading ? (
               <div className="col-12 loading_data">
                    <Skeleton height={20} count={22} />
                </div>
        ) : selectedComponent.length > 0 ? (
            selectedComponent
        ) : (
            <p className="no_data_available">{translate("noNews")}</p>
        )}
        </Fragment>
    );
};

export default FeatureLayout;
